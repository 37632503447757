import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import "react-toastify/dist/ReactToastify.css";

import { useLoginUrlLazyQuery } from "../graphql";
import { LoadingScreen, LOGIN_MESSAGE } from "../components/ui_palette/Loading";

const Login = () => {
  const router = useRouter();
  const [tenantId, setTenantId] = useState("");
  const [redirectUri, setRedirectUri] = useState("");
  const [authCode, setAuthCode] = useState<string | null>(null);

  const [getRedirectQuery, { error, data }] = useLoginUrlLazyQuery({
    variables: {
      input: {
        tenantId,
        clientId: process.env.NEXT_PUBLIC_CLIENT_ID ?? "",
        redirectUri,
      },
    },
  });

  useEffect(() => {
    if (tenantId) {
      getRedirectQuery();
    }
  }, [tenantId]);

  useEffect(() => {
    const url = window.location.pathname;
    if (data && data.loginUrl && url !== "/facebookbutton") {
      localStorage.setItem("cc", data.loginUrl.cc);
      router.push(data.loginUrl.redirectUrl);
    }
  }, [data]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const accessTokenQuery = params.get("token");
    const url = window.location.pathname;
    const tokenExist = localStorage.getItem("token") || accessTokenQuery;
    const lastPath = localStorage.getItem("lastPath");

    if (url === "/facebookbutton") {
      router.push(window.location.href);
      return;
    }

    if (tokenExist && url === "/") {
      const newUrl = lastPath && lastPath !== "/" ? lastPath : "/DashboardHome";
      router.push(newUrl);
      return;
    }

    if (!tokenExist) {
      const urlTenant = window.location.href.split("//")[1].split(".")[0];
      const params = new URLSearchParams(window.location.search);
      const authCode = params.get("code");
      if (!authCode) {
        setTenantId(urlTenant);
        setRedirectUri(
          (process.env.NEXT_PUBLIC_REDIRECT_URI ?? "").replace("*", urlTenant)
        );
      }
      setAuthCode(authCode);
      return;
    }

    router.push(window.location.href);
  });

  if (error) {
    localStorage.clear();
    window.location.reload();
  }

  return <LoadingScreen message={authCode ? LOGIN_MESSAGE : undefined} />;
};

export default Login;
